/** @format */

.corners {
	position: relative;
	width: "10%"; /* for demo purposes */
	padding: 20px;
}

.top,
.bottom {
	position: absolute;
	width: 20px;
	height: 20px;
}

.top {
	top: 0;
	border-top: 3px solid;
}

.bottom {
	bottom: 0;
	border-bottom: 3px solid;
}

.left {
	left: 0;
	border-left: 3px solid;
}

.right {
	right: 0;
	border-right: 3px solid;
}
